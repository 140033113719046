@media print {
    /* Hide all elements inside #root */
    .rpv-print__body-printing #root {
        display: none;
    }

    /* Show the prepared pages */
    .rpv-print__zone {
        display: block;
    }
}
